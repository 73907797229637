.radiobox {
	display: inline-flex;
	height: 24px;
	position: relative;
	align-items: center;
	overflow: hidden;

	input {
		position: absolute;
		z-index: 1;
		top: -100px;
		left: 0px;
		opacity: 0;

		&:checked ~ label:after {
			color: #fff;
		}
	}	

	label {
		padding-left: 32px;
		font-size: 12px;

		&:before {
			content: '';
			position: absolute;
			top: 0px;
			left: 0px;
			width: 24px;
			height: 24px;
			background: #DEE2E6;
			border-radius: 100%;
			transition: all 0.3s ease 0s;
			cursor: pointer;
		}

		&:after {
			content: '';
			width: 10px;
			height: 10px;
			border-radius: 100px;
			color: #DEE2E6;
			background-color: #DEE2E6;
			display: inline-block;
			position: absolute;
			left: 7px;
			top: 7px;
			font-size: 68px;
			font-weight: 600;
			transition: all 0.3s ease 0s;
			cursor: pointer;
		}
	}

	&.radiobox-primary {
		input {
			&:checked ~ label:before {
				background: map-get($theme-colors, 'primary');
			}
			&:checked ~ label:after {
				background-color: #fff;
			}
		}
	}
	&.radiobox-secondary {
		input {
			&:checked ~ label:before {
				background: map-get($theme-colors, 'secondary');
			}
			&:checked ~ label:after {
				background-color: #fff;
			}
		}
	}
	&.radiobox-success {
		input {
			&:checked ~ label:before {
				background: map-get($theme-colors, 'success');
			}
			&:checked ~ label:after {
				background-color: #fff;
			}
		}
	}
	&.radiobox-danger {
		input {
			&:checked ~ label:before {
				background: map-get($theme-colors, 'danger');
			}
			&:checked ~ label:after {
				background-color: #fff;
			}
		}
	}
	&.radiobox-warning {
		input {
			&:checked ~ label:before {
				background: map-get($theme-colors, 'warning');
			}
			&:checked ~ label:after {
				background-color: #fff;
			}
		}
	}
	&.radiobox-info {
		input {
			&:checked ~ label:before {
				background: map-get($theme-colors, 'info');
			}
			&:checked ~ label:after {
				background-color: #fff;
			}
		}
	}
	&.radiobox-light {
		input {
			&:checked ~ label:before {
				background: map-get($theme-colors, 'light');
			}
			&:checked ~ label:after {
				background-color: #fff;
			}
			&:checked ~ label:after {
				color: $qd-body-text-color;
			}			
		}
	}
	&.radiobox-dark {
		input {
			&:checked ~ label:before {
				background: map-get($theme-colors, 'dark');
			}
			&:checked ~ label:after {
				background-color: #fff;
			}
		}
	}

	&.radiobox-lg {
		height: 30px;

		label {
			padding-left: 40px;

			&:before {
				width: 30px;
				height: 30px;
			}

			&:after {
				left: 10px;
				top: 1px;				
			}
		}
	}
}