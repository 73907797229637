.checkbox {
	display: inline-flex;
	height: 24px;
	position: relative;
	align-items: center;
	justify-content: start;

	input {
		position: absolute;
		z-index: 1;
		top: 0px;
		left: 0px;
		opacity: 0;

		&:checked ~ label:after {
			color: #fff;
		}
	}	

	label {
		padding-left: 32px;

		&:before {
			content: '';
			position: absolute;
			top: 0px;
			left: 0px;
			width: 24px;
			height: 24px;
			background: #DEE2E6;
			z-index: 2;
			border-radius: 6px;
			transition: all 0.3s ease 0s;
			cursor: pointer;
		}

		&:after {
			content: 'L';
			-ms-transform: scaleX(-1) rotate(-35deg);
			-webkit-transform: scaleX(-1) rotate(-35deg);
			transform: scaleX(-1) rotate(-35deg);
			color: #DEE2E6;
			display: inline-block;
			position: absolute;
		    left: 8px;
		    top: -2px;
			z-index: 3;
			font-size: 16px;
			font-weight: 600;
			transition: all 0.3s ease 0s;
			cursor: pointer;
		}
	}

	&.checkbox-primary {
		input {
			&:checked ~ label:before {
				background: map-get($theme-colors, 'primary');
			}			
		}
	}
	&.checkbox-secondary {
		input {
			&:checked ~ label:before {
				background: map-get($theme-colors, 'secondary');
			}			
		}
	}
	&.checkbox-success {
		input {
			&:checked ~ label:before {
				background: map-get($theme-colors, 'success');
			}			
		}
	}
	&.checkbox-danger {
		input {
			&:checked ~ label:before {
				background: map-get($theme-colors, 'danger');
			}			
		}
	}
	&.checkbox-warning {
		input {
			&:checked ~ label:before {
				background: map-get($theme-colors, 'warning');
			}			
		}
	}
	&.checkbox-info {
		input {
			&:checked ~ label:before {
				background: map-get($theme-colors, 'info');
			}			
		}
	}
	&.checkbox-light {
		input {
			&:checked ~ label:before {
				background: map-get($theme-colors, 'light');
			}			
			&:checked ~ label:after {
				color: $qd-body-text-color;
			}			
		}
	}
	&.checkbox-dark {
		input {
			&:checked ~ label:before {
				background: map-get($theme-colors, 'dark');
			}			
		}
	}

	&.checkbox-lg {
		height: 30px;

		label {
			padding-left: 40px;

			&:before {
				width: 30px;
				height: 30px;
			}

			&:after {
				left: 10px;
				top: 1px;				
			}
		}
	}
}