.app-sidebar {
	background-color: map-get($theme-colors, 'sidebar');

	.sidebar-logo {
		border-bottom: 1px solid #35374A;
		padding-top: 12px;
		padding-bottom: 12px;

		a {
			img {
				height: 45px;
			}
		}
	}

	.sidebar-menu {
		height: 100%;

		.menu-group {
			list-style: none;

			&.hide-desktop {
				display: none;
			}

			li {
				margin-bottom: 0.125rem;
				margin-top: 0.125px;
				transition: max-height 0.3s ease 0s;
				position: relative;

				a {
					color: #9499A1;
					text-decoration: none;
					transition: all 0.3s ease 0s;
					height: 40px;
					border-radius: $border-radius;
					padding-left: 15px;
					padding-right: 7px;
					background-color: #212233;
					font-size: 14px;
					letter-spacing: .50px;

					&:hover {
						color: #fff;

						svg {
							color: #fff;
						}
					}

					.arrow {
						transition: all 0.3s ease 0s;
					}
				}

				&.active {
					max-height: 1000px;

					a {
						background-color: map-get($theme-colors, 'primary');
						color: #fff;
						position: relative;

						&::after {
							content: '';
							position: absolute;
							width: 6px;
							height: 36px;
							background-color: map-get($theme-colors, 'primary');
							border-radius: $border-radius;
							right: -14px;
							opacity: .8;

						}

						svg {
							color: #fff;
						}

						.arrow {
							transform: rotate(90deg);
						}
					}
				}

				.sub-menu-group {
					margin-top: 0.5rem;
					padding: 0px;
					padding-left: 10px;
					list-style: none;

					li {
						margin-bottom: 0.50rem;
						margin-top: 0.50px;
						position: relative;
						overflow: inherit;
						
						&::before {
							content: '';
							width: 6px;
							height: 6px;
							background-color: #9499A1;
							position: absolute;
							border-radius: 100%;
							top: 9px;
							left: 0px;
						}

						a {
							background: none;
							color: #b7bcc6;
							padding-left: 1.25rem;
							transition: all 0.3s ease 0s;
							//opacity: .6;

							&:hover {
								color: #fff;
							}

							&::after {
								width: 0px;
								height: 0px;
							}
						}

						&.active {
							a {
								color: #fff;
								opacity: 1;

								&:hover {
									color: #fff;
								}
							}

							&::before {
								background-color: map-get($theme-colors, 'primary');
							}
							
							&::after {
								content: '';
								width: 12px;
								height: 12px;
								background-color: map-get($theme-colors, 'primary');
								position: absolute;
								border-radius: 100%;
								top: 6px;
								left: -3px;
								opacity: .45;
							}
						}
					}
				}
			}
		}

		.menu-title {
			position: relative;
			padding-left: 2rem;

			&:before {
				content: '';
				position: absolute;
				width: 16px;
				height: 1px;
				background-color: #9499A1;
				left: 0px;
				top: 10px;
				opacity: .6;
			}

			small {
				font-size: 12px;
				line-height: 0px;
				opacity: .6;
			}
		}

		.social {
			text-align: center;
			position: relative;
			width: 100%;
			bottom: 0px;

			.youtube {
				display: inline-block;
				border-radius: 5px;
				background-color: #FF0000;
				width: 32px;
				height: 32px;
				line-height: 28px;
				color: #fff;
				text-decoration: none;
				font-size: 14px;
				margin: 5px;
			}
	
			.twitter {
				display: inline-block;
				border-radius: 5px;
				width: 32px;
				height: 32px;
				line-height: 28px;
				color: #fff;
				text-decoration: none;
				font-size: 14px;
				background-color: #333;
				margin: 5px;
			}
	
			.instagram {
				display: inline-block;
				border-radius: 5px;
				width: 32px;
				height: 32px;
				line-height: 28px;
				color: #fff;
				text-decoration: none;
				font-size: 14px;
				background: radial-gradient(circle at 33% 100%, #fed373 4%, #f15245 30%, #d92e7f 62%, #9b36b7 85%, #515ecf);
				margin: 5px;
			}
		}
	}

	.sidebar-footer {
		border-top: 1px solid #35374A;
		position: sticky;
		bottom: 0px;
		left: 0px;
		width: 264px;
		padding: 1rem 2rem;
		z-index: 2;
		background-color: map-get($theme-colors, 'sidebar');

		.sidebar-footer-button {
			display: flex;
			background-color: #3E4155;
			width: 100%;
			height: 40px;
			justify-content: center;
			align-items: center;
			border-radius: $border-radius;
			color: #fff;
			font-size: 14px;
			text-decoration: none;
		}
	}
}

@media (max-width : 991px) {
	.app-sidebar {
		.sidebar-menu {
			.menu-group {
				&.hide-desktop {
					display: block;
				}
			}

			.social {
				position: relative;
			}
		}
	}
}