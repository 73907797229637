.main-header {
	height: 70px;
	background-color: #fff;
	box-shadow: 0 2px 48px 0 rgba(0,0,0,0.06);
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	margin-left: 264px;
	transition: all 0.3s ease 0s;
	z-index: 5;

	.mobile-logo {
		display: none;
	}

	.top-nav {
		display: inline-block;

		.menu-group {
			list-style: none;
			padding: 0px;
			margin: 0px;
			display: flex;
			margin-left: 2rem;
			margin-top: 12px;

			li.sub-menu {
				margin-right: 0.375rem;
				position: relative;

				&.open-mega-menu {

					&:hover {
						.mega-menu-wrapper {
							visibility: visible;
							opacity: 1;
							z-index: 1;
							transform: translateY(0%);
							transition-delay: 0s, 0s, 0.3s;
						}
					}
				}

				&.open-sub-menu {

					&:hover {
						.top-menu-group-wrapper {
							visibility: visible;
							opacity: 1;
							z-index: 1;
							transform: translateY(0%);
							transition-delay: 0s, 0s, 0.3s;
						}
					}
				}

				.menu-link {
					display: block;
					font-size: 14px;
					letter-spacing: 1px;
					text-decoration: none;
					padding: 0.40rem;
					color: $textColor;
				}

				&:first-child {
					.menu-link {
						padding-left: 0;
					}
				}

				&.sub-menu {
					.menu-link {
						position: relative;
					}
				}

				&:last-child {
					margin-right: 0rem;
				}

				.mega-menu-wrapper {
					position: absolute;
					left: 0px;
					padding-top: 10px;
					opacity: 0;
					visibility: hidden;
					transform: translateY(-0.5em);
					z-index: -1;
					transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
					min-width: 490px;

					&.large-menu {
						min-width: 675px;
					}

					&.small-menu {
						min-width: 400px;
					}

					.mega-menu {
						background-color: #fff;
						box-shadow: 0 2px 48px 0 rgba(0,0,0,0.12);
						border-radius: $border-radius;
						padding: 2rem 1rem;

						.cover {
							overflow: hidden;
							border-radius: $border-radius;
							position: relative;

							&:before {
								content: "";
								position: absolute;
								width: 100%;
								height: 100%;
								background: map-get($theme-colors, 'primary');
								opacity: .8;
								z-index: 1;
							}

							.text {
								position: absolute;
								width: 100%;
								height: 100%;
								z-index: 2;
								top: 0px;
							}
						}

						.mega-sub-menu {
							padding: 0px;
							margin: 0px;
							list-style: none;

							li {
								margin-bottom: 0.375rem;

								&:last-child {
									margin-bottom: 0rem;
								}

								a {
									color: $qd-body-text-color;
									text-decoration: none;
								}
							}
						}
					}
				}

				.top-menu-group-wrapper {
					position: absolute;
					left: 0px;
					padding-top: 10px;
					opacity: 0;
					visibility: hidden;
					transform: translateY(-0.5em);
					z-index: -1;
					transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
					min-width: 250px;

					.top-menu-group {
						background-color: #fff;
						box-shadow: 0 2px 48px 0 rgba(0,0,0,0.12);
						border-radius: $border-radius;
						padding: 1rem 1.5rem;
						list-style: none;

						a {
							display: block;
							height: 36px;
							overflow: hidden;
							color: $qd-body-text-color;
							text-decoration: none;
							transition: all 0.3s ease 0s;
							font-size: 14px;
							letter-spacing: 1px;

							svg {
								color: #fff;
								transition: all 0.3s ease 0s;
							}

							&:hover {
								color: map-get($theme-colors, 'primary');

								svg {
									color: map-get($theme-colors, 'primary');
								}
							}

							.arrow {
								transition: all 0.3s ease 0s;
							}
						}

						.top-sub-menu {
							position: relative;

							&:hover {
								.top-level-menu-wrapper {
									visibility: visible;
									opacity: 1;
									z-index: 1;
									transform: translateY(0%);
									transition-delay: 0s, 0s, 0.3s;
								}
							}

							.top-level-menu-wrapper {
								position: absolute;
								top: 0px;
								left: 185px;
								padding-left: 2rem;
								opacity: 0;
								visibility: hidden;
								transform: translateY(-0.5em);
								z-index: 2;
								transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;

								.top-sub-menu-group {
									background-color: #fff;
									box-shadow: 0 2px 48px 0 rgba(0,0,0,0.12);
									border-radius: $border-radius;
									padding: 1rem 2rem;
									list-style: none;
									width: 180px;
								}
							}
						}
					}
				}
			}
		}
	}

	.sidebar-menu-trigger {
		cursor: pointer;
		display: none;
		width: 32px;
		height: 40px;
		text-indent: -9999em;
		float: left;
		position: relative;
		margin-top: 0px;
		margin-left: 0.5rem;	
		z-index: 4;	

		span,
		span:before,
		span:after {
			-moz-transition: all 0.4s;
			-o-transition: all 0.4s;
			-webkit-transition: all 0.4s;
			transition: all 0.4s;
			background-color: map-get($theme-colors, 'sidebar');
			display: block;
			position: absolute;
			width: 30px;
			height: 2px;
			right: 0;
		}

		span:before,
		span:after {
			-moz-transition: all 0.4s;
			-o-transition: all 0.4s;
			-webkit-transition: all 0.4s;
			transition: all 0.4s;
			background-color: map-get($theme-colors, 'sidebar');
			display: block;
			position: absolute;
			width: 30px;
			height: 2px;
			right: 0;
			width: 75%;
		}


		span:before,
		span:after {
			content: "";
		}

		span {
			top: 19px;

			&:before {
				-moz-transform-origin: 33% 100%;
				-ms-transform-origin: 33% 100%;
				-webkit-transform-origin: 33% 100%;
				transform-origin: 33% 100%;
				top: -10px;
				z-index: 10;
			}

			&:after {
				-moz-transform-origin: 33% 0;
				-ms-transform-origin: 33% 0;
				-webkit-transform-origin: 33% 0;
				transform-origin: 33% 0;
				top: 10px;
			}
		}

		&.active span,
			&.active span:before,
			&.active span:after {
				background-color: transparent;
				width: 100%;
			}

			&.active span:before {
				-moz-transform: translateY(6px) translateX(1px) rotate(45deg);
				-ms-transform: translateY(6px) translateX(1px) rotate(45deg);
				-webkit-transform: translateY(6px) translateX(1px) rotate(45deg);
				transform: translateY(6px) translateX(1px) rotate(45deg);
				background-color: map-get($theme-colors, 'sidebar');
			}

			&.active span:after {
				-moz-transform: translateY(-6px) translateX(1px) rotate(-45deg);
				-ms-transform: translateY(-6px) translateX(1px) rotate(-45deg);
				-webkit-transform: translateY(-6px) translateX(1px) rotate(-45deg);
				transform: translateY(-6px) translateX(1px) rotate(-45deg);
				background-color: map-get($theme-colors, 'sidebar');
			}
	}

	.header-tools {
		height: 70px;
		padding-right: 2rem;
		z-index: 3;

		.youtube {
			padding-left: 10px;
			padding-right: 10px;
			border-radius: 5px;
			background-color: #FF0000;
			height: 32px;
			line-height: 32px;
			color: #fff;
			text-decoration: none;
			font-size: 14px;
		}

		.twitter {
			padding-left: 10px;
			padding-right: 10px;
			border-radius: 5px;
			height: 32px;
			line-height: 29px;
			color: #fff;
			text-decoration: none;
			font-size: 14px;
			background-color: #333;
		}

		.instagram {
			padding-left: 8px;
			padding-right: 8px;
			border-radius: 5px;
			height: 32px;
			line-height: 29px;
			color: #fff;
			text-decoration: none;
			font-size: 14px;
			background: radial-gradient(circle at 33% 100%, #fed373 4%, #f15245 30%, #d92e7f 62%, #9b36b7 85%, #515ecf)
		}

		.header-dropdown {
			text-decoration: none;
			display: block;
			height: 40px;
			width: 40px;
			overflow: hidden;

			&:after {
				border: none !important;
				display: initial !important;
				margin: 0px !important;
			}
		}		
	}
}

@media (max-width : 991px) {
	.main-header {
		margin-left: 0px !important;
		position: absolute !important;

		.mobile-logo {
			display: block;
			margin-top: 12px;
			margin-left: 15px;
			z-index: 2;
			
			img {
				width: 145px;
				height: 45px;
			}
		}

		.top-nav {
			position: absolute;
			top: 0px;
			left: 0px;
			right: 0px;
			padding-bottom: 48px;

			.menu-group {
				box-shadow: 0 2px 48px 0 rgba(0,0,0,0.04) !important;
				margin: 0px;
				padding-top: 70px;
    		background-color: var(--bs-header-bg);
				display: block;
				height: 70px;
				overflow: hidden;

				&.sliding {
					height: auto !important;
				}

				.sub-menu {
					border-top: 1px solid var(--bs-border-color);
					margin-right: 0px !important;
					height: 57px !important;
					overflow: hidden !important;

					&.open {
						height: auto !important;

						a {
							.arrow {
								transform: rotate(0deg);
							}
						}
					}

					.menu-link {
						padding: 1rem !important;
						display: flex !important;
						justify-content: space-between;
						align-items: center;

						.arrow {
							transform: rotate(-90deg);
							transition: all 0.3s ease 0s;
						}
					}

					.mega-menu-wrapper {
						position: relative !important;
						z-index: 1 !important;
						visibility: visible !important;
						opacity: 1 !important;
						padding: 0px !important;
						width: 100% !important;
						min-width: 100% !important;
						transform: translateY(0em) !important;

						.mega-menu {
							box-shadow: none !important;
							border-radius: 0px !important;
							padding: 0px !important;
							padding-left: 0.4rem !important;
							padding-right: 0.4rem !important;
							padding-bottom: 1rem !important;
						}
					}

					.top-menu-group-wrapper {
						position: relative !important;
						z-index: 1 !important;
						visibility: visible !important;
						opacity: 1 !important;
						transform: translateY(0em) !important;
						padding: 0px !important;

						.top-menu-group {
							box-shadow: none !important;
							border-radius: 0px !important;
							padding: 0px !important;
							padding-left: 1rem !important;
							padding-right: 1rem !important;

							li {
								border-bottom: 1px solid var(--bs-border-color);
								
								&:last-child {
									border: none;
								}

								a {
									height: 50px !important;
								}
							}

							.top-sub-menu {
								height: 50px !important;
								overflow: hidden !important;

								&.open {
									height: auto !important;

									a {
										.arrow {
											transform: rotate(90deg);
										}
									}
								}

								.top-level-menu-wrapper {
									position: relative !important;
									z-index: 1 !important;
									visibility: visible !important;
									opacity: 1 !important;
									transform: translateY(0em) !important;
									padding: 0px !important;
									left: 0px !important;

									.top-sub-menu-group {
										box-shadow: none !important;
										border-radius: 0px !important;
										padding: 0px !important;
										padding-left: 2rem !important;
										width: 100% !important;

										li {
											border-bottom: 1px solid var(--bs-border-color);

											&:last-child {
												border: none !important;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.sidebar-menu-trigger {
			display: inline-block;
		}

		.header-tools {
			padding-right: 1rem;
		}
	}
}

@media (min-width : 992px) {
	.main-header {
		margin-left: 264px !important;
	}
}