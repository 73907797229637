.search-container {
  position: relative;

  .search-input {
    height: 50px;
    position: relative;
    border-radius: 5px;

    input {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      outline: none;
      border: 1px solid #424455;
      border-radius: 5px;
      background-color: #424455;
      color: #fff;
      letter-spacing: 1px;
      font-size: 14px;
      padding-left: 45px;
      padding-right: 20px;
      transition:all .3s ease 0s;

      &:focus {
        border: 1px solid #585A70;
      }

      &::placeholder {
        color: #7C7D89;
    }
    }

    svg {
      position: absolute;
      color: #7C7D89;
      left: 12px;
      top: 12px;
    }
  }

  .search-results {
    position: absolute;
    text-align: left;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    width: 100%;
    top: 60px;
    left: 0px;
    box-shadow: 0 2px 48px 0 rgba(0,0,0,0.12);
    overflow: hidden;
    z-index: 3;

    &.results-hide {
      display: none;
    }

    .search-title {
      display: flex;
      justify-content: space-between;
      background-color: #E9ECEF;
      border-radius: 2px;
      padding: 3px 10px;
      margin-bottom: 10px;
      color: #3D475C;
      font-weight: 500;
      font-size: 14px;

      small {
        font-weight: 400;
      }
    }

    ul {
      padding: 0px;
      margin: 0px;
      list-style: none;

      li {
        a {
          display: block;
          margin-bottom: 5px;
          border-radius: 5px;
          padding: 5px;
          text-decoration: none;
          transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;

          &:hover {
            background-color: map-get($theme-colors, 'light');
          }

          strong {
            color: map-get($theme-colors, 'dark');
            font-size: 14px;
            font-weight: 500;
            display: inline-block;
            margin-right: 5px;
            width: 56px;
          }

          span {
            color: map-get($theme-colors, 'dark');
            font-size: 14px;
            font-weight: 400;
          }
        }

        &:last-child {
          a {
            margin-bottom: 0px;
          }
        }
      }
    }

    p {
      font-size: 14px;
    }
  }
}