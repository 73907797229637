#__next {
	min-height: 100vh;
}

.app-content {
	min-height: calc(100vh - 123px);
}

.app-root {
	min-height: 100vh;

	.app-sidebar {
		width: 264px;
		position: fixed;
		left: 0px;
		top: 0px;
		bottom: 0px;
		transition: all 0.3s ease 0s;
		z-index: 3;

		&.open {
			left: 0px !important;
			display: block;
		}
	}

	.app-main {
		margin-left: 264px;
		transition: all 0.3s ease 0s;
		z-index: 1;
		min-height: 100vh;
	}

	.menu-backdrop {
		position: fixed;
		top: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;
		z-index: 2;
		cursor: pointer;
		background: map-get($theme-colors, 'sidebar');
		opacity: .5;
		display: none;

		&.active {
			display: block;
		}
	}

	.app-content {
		margin-top: 70px;
	}
}

.card-shadow {
	box-shadow: 0 2px 28px 0 rgba(0,0,0,0.06);
	background-color: #fff;
	border-radius: 5px;
}

.item-icon {
	width: 40px;
	height: 40px;
	line-height: 40px;
	border-radius: 5px;
	font-weight: 500;
	font-size: 14px;
	background-color: #E9ECEF;
	text-align: center;
	color: $headings-color;
	display: inline-block;
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: 40px;
}

.item-icon-2 {
	width: 76px;
	height: 30px;
	line-height: 30px;
	border-radius: 5px;
	font-weight: 500;
	font-size: 14px;
	background-color: #E9ECEF;
	text-align: center;
	color: $headings-color;
	display: inline-block;
}

.table-border-dashed {
	vertical-align: baseline !important;
}

.table-border-dashed>:not(caption)>*>* {
	padding-top: 1rem !important;
	padding-bottom: 1rem !important;
	border-bottom-style: dashed;
}

.breadcrumb {
	margin-bottom: 0px !important;
	font-size: 14px !important;

	.breadcrumb-item {
		color: #b7bbc0 !important;

		a {
			text-decoration: none;
			color: #b7bbc0;
			font-size: 14px;

			&:hover {
				color: var(--bs-primary);
			}
		}
	}
}

.page-title-container {
	margin-bottom: 30px;
	display: flex;
	justify-content: space-between;

	.fund-tools {
		background-color: #333546;
		border-radius: 5px;
		width: 240px;
	}
}

.form-label {
	color: $headings-color;
	font-size: 14px;
	font-weight: 500;
	left: .42px;
}

.w-200 {
	width: 200px;
}

.fon-karsilastirma {
	width: 300px;
	border-right: 1px solid #f5f5f5;
	padding: 40px;
	text-align: center;
}

.w-270 {
	width: 270px;
}

.w-80 {
	width: 80px;
}

.height-100 {
	height: 100px;
}

.show-sm {
	display: none;
}


@media (max-width : 991px) {
	.show-sm {
		display: block !important;
	}

	.app-root {
		.app-sidebar {
			left: -264px !important;
		}

		.app-main {
			margin-left: 0px !important;
		}
	}

	.page-title-container {
		flex-direction: column;

		.page-title {
			margin-bottom: 30px;
		}

		.fund-tools {
			width: 100%;
		}
	}
}

.table-link {
	line-height: 22px;
	text-decoration: none;
	color: map-get($theme-colors, 'sidebar');
}

.data-table {
	.rdt_TableRow {
		font-size: 14px !important;
		padding-top: 10px;
    padding-bottom: 10px;
	}
}

.react-tagsinput {
	border: var(--bs-border-width) solid var(--bs-border-color);
	border-color: var(--bs-border-color) !important;
	border-bottom: none !important;

	.react-tagsinput-tag {
    background-color: #E9ECEF;
    border-radius: 3px;
    border: 1px solid #E9ECEF;
    color: #9499A1;
    display: inline-block;
		letter-spacing: .40px;

		.react-tagsinput-remove {
			color: #9499A1;
			text-decoration: none;
		}
	}
}

.btn-warning {
	color: #fff !important;
}

.scroll-hide {
  overflow: -moz-scrollbars-none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.scroll-hide::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

@media (min-width : 992px) {
	.app-root {
		.app-sidebar {
			left: 0 !important;
		}

		.app-main {
			margin-left: 264px !important;
		}

		.menu-backdrop {
			display: none !important;
		}
	}
}