.radial-chart {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .chart-content {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .emoji {
      font-size: 20px;
      display: block;
      margin-bottom: 5px;
    }
  }
}