@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

@import 'theme-colors';
@import 'variables';
@import 'switch';
@import 'radiobox';
@import 'checkbox';
@import 'main';
@import 'sidebar-menu';
@import 'header';
@import 'search-input';
@import 'radial-chart';
@import 'footer';

@import '~bootstrap/scss/bootstrap';