$theme-colors: (
  'primary':    #5151F9,
  'secondary':  #6C757D,
  'success':    #11B981,
  'info':       #0EA5E9,
  'warning':    #FFBB2A,
  'danger':     #E11D48,
  'light':      #F8F9FA,
  'dark':       #21242A,
  'sidebar':    #2A2C3E
);

$qd-body-text-color: #9499A1;
$textColor: $qd-body-text-color;