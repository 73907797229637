.qd-switch {
	position: relative;
	display: inline-block;
	width: 50px;
	height: 30px;

	input {
		opacity: 0;
		width: 0;
		height: 0;
		outline: none !important;
	}

	input:checked + .slider {
		background-color: var(--bs-primary);
	}

	&.switch-primary {
		input:checked + .slider {
			background-color: var(--bs-primary);
		}
	}

	&.switch-secondary {
		input:checked + .slider {
			background-color: var(--bs-secondary);
		}
	}

	&.switch-success {
		input:checked + .slider {
			background-color: var(--bs-success);
		}
	}

	&.switch-danger {
		input:checked + .slider {
			background-color: var(--bs-danger);
		}
	}

	&.switch-warning {
		input:checked + .slider {
			background-color: var(--bs-warning);
		}
	}

	&.switch-info {
		input:checked + .slider {
			background-color: var(--bs-info);
		}
	}

	&.switch-light {
		input:checked + .slider {
			background-color: var(--bs-light);
		}
	}

	&.switch-dark {
		input:checked + .slider {
			background-color: var(--bs-dark);
		}
	}

	input:checked + .slider:before {
		-webkit-transform: translateX(20px);
		-ms-transform: translateX(20px);
		transform: translateX(20px);
	}

	.slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: var(--bs-border-color);
		-webkit-transition: .3s;
		transition: .3s;
		border-radius: 34px;

		&:before {
			position: absolute;
			content: "";
			height: 22px;
			width: 22px;
			left: 4px;
			bottom: 4px;
			background-color: #fff;
			-webkit-transition: .3s;
			transition: .3s;
			border-radius: 50%;
		}
	}

	&.switch-lg {
		width: 60px;
		height: 40px;

		.slider {
			&:before {
				height: 30px;
				width: 30px;
				left: 5px;
				bottom: 5px;
			}
		}
	}

	&.switch-sm {
		width: 30px;
		height: 20px;

		input:checked + .slider:before {
			-webkit-transform: translateX(10px);
			-ms-transform: translateX(10px);
			transform: translateX(10px);
		}

		.slider {
			&:before {
				height: 16px;
				width: 16px;
				left: 2px;
				bottom: 2px;
			}
		}
	}
}