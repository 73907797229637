$border-radius: 5px;
$headings-color: #3D475C;
$text-color: #5a5e64;
$breadcrumb-divider: url("data:image/svg+xml,<svg width='6px' height='10px' viewBox='0 0 6 10' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'><g transform='translate(-363.000000, -134.000000)' stroke='#{$text-color}'><g transform='translate(364.000000, 135.000000)'><polyline points='0 8 4 4 0 0'></polyline></g></g></g></svg>");

body {
  height: 100vh;
  color: $text-color !important;
  letter-spacing: 0.42px;
  background-color: #F5F8FA !important;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  letter-spacing: 1px;
}

strong {
  font-weight: 500 !important;
}

.font-xsmall {
  font-size: 10px !important;
}
.font-small {
  font-size: 14px !important;
}

.font-normal {
  font-size: 16px;
}

.text-heading {
  color: #3D475C;
}

.fw-500 {
  font-weight: 500;
}

.text-color {
  color: $text-color !important;
}

.btn-success {
  color: #fff !important;
}

.mt-n-10 {
  margin-top: -10px;
}

.w-300 {
  width: 300px;
}

.dikey {
  writing-mode: vertical-rl; /* Metni dikey yazmak için */
  text-orientation: mixed; /* Harflerin yönünü ayarlamak için */
  transform: rotate(180deg); /* Metni 180 derece döndürerek sol alttan başlayacak şekilde yazmak için */
  text-align: start;
}

.border-dashed {
  border: 1px dashed var(--bs-border-color);
}

.border-bottom-dashed {
  border-bottom: 1px dashed var(--bs-border-color);
}

.box-48 {
  width: 48px;
  height: 48px;
}

.takvim-icon {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hisse-card {
  height: 500px;
  overflow: scroll;
}

.temettu-card {
  height: 800px;
  overflow: scroll;
}

.fiyat-card {
  height: 800px;
  overflow: hidden;
}

.ps-6 {
  padding-left: 80px;
}

.temettu-takvimi {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    border-left: 1px dashed var(--bs-border-color);
    top: 0px;
    left: 60px;
    z-index: 1;
  }
}

.iletisim-youtube {
  display: inline-block;
  padding-left: 20px;
  padding-right: 20px;
  padding-top:10px;
  padding-bottom: 10px;
  border-radius: 5px;
  background-color: #FF0000;
  line-height: 32px;
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  margin-bottom: 10px;
}

.iletisim-twitter {
  display: inline-block;
  padding-left: 20px;
  padding-right: 20px;
  padding-top:10px;
  padding-bottom: 10px;
  border-radius: 5px;
  line-height: 29px;
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  background-color: #333;
  margin-bottom: 10px;
}

.iletisim-instagram {
  display: inline-block;
  padding-left: 20px;
  padding-right: 20px;
  padding-top:10px;
  padding-bottom: 10px;
  border-radius: 5px;
  line-height: 29px;
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  background: radial-gradient(circle at 33% 100%, #fed373 4%, #f15245 30%, #d92e7f 62%, #9b36b7 85%, #515ecf);
  margin-bottom: 10px;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.blog {
  border-radius: 8px !important;
  overflow: hidden;
  display: block;
  text-decoration: none;

  .blog-image-container {
    width: 100%;
    height: 200px;
    position: relative;
    overflow: hidden;
  }

  h2 {
    font-size: 22px;
    line-height: 32px;
    letter-spacing: .7px;
    min-height: 100px;
  }

  p {
    color: $text-color;
    margin-bottom: 0px;
  }
}

.blog-detay-image-container {
  width: 100%;
  height: 600px;
  position: relative;
  overflow: hidden;
  margin-bottom: 24px;
  border-radius: 8px;
}

.blog-detay {
  border-radius: 8px !important;
  overflow: hidden;
  display: block;
  text-decoration: none;

  h2 {
    font-size: 22px;
    line-height: 32px;
    letter-spacing: .7px;
  }

  p {
    color: $text-color;
  }

  strong {
    color: $headings-color;
  }
}

.w-1024 {
  width: 1024px;
}

.w-45 {
  width: 45%;
}

.w-400 {
  width: 400px;
}

.w-250 {
  width: 250px;
}

.w-120 {
  width: 120px;
}

@media (max-width : 991px) {
  .blog-detay-image-container {
    height: 250px;
  }
}

.bg-sidebar {
  background-color: map-get($theme-colors, 'sidebar');
}